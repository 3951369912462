import React, { useRef, useEffect } from 'react';

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  const playBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (playBtnRef.current) {
        playBtnRef.current.click();
      }
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="launchContainer">
      <div>
        <h1>{client.description}</h1>
        <button
          ref={playBtnRef}
          id="playBtn"
          className="ui massive yellow circular icon button"
          onClick={() => props.Launch()}
        >
          <i className="play icon"></i>
        </button>
      </div>
    </div>
  );
};
